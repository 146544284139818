<template>
  <div class="follow-up">
    <!-- navbar组件 -->
    <navbar title="我的跟进" />
    <div class="follow-box">
      <template v-if="itemList && itemList.length > 0">
        <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad" offset="50">
          <div class="follow-item" v-for="item in itemList" :key="item.id">
            <div class="follow-content">
              <div class="follow-msg">
                <div class="follow-icon">{{ item.realName.slice(0,1) }}</div>
                <div class="item-box">
                  <div class="item-msg">
                    <div class="item-name">{{ item.realName }}</div>
                    <div class="item-phone">{{ item.mobile | phoneFilter }}</div>
                  </div>
                  <div class="item-intention">
                    <p>意向房源：</p>
                    <span>{{ item.houseTitle }}</span>
                  </div>
                  <div class="item-arrive">预计到访：{{getTimes.timestampToTime(item.createTime)}}</div>
                  <div class="item-words">留言：{{ escapeHtml(item.content) }}</div>
                  <div class="item-lately">最近一次跟进：{{getTimes.timestampToTime( item.lastFollowTime )}}</div>
                </div>
              </div>
              <div :class="[item.status == 1 ? 'follow-label' : 'active follow-label']">
                {{ item.status == 1 ? "已接单" : item.flowStatus }}
              </div>
            </div>
            <div class="follow-bottom">
              <div class="item-reception">接待人：{{ item.receiveUserName }}</div>
              <div class="item-btn" @click="goDetail(item.id)">
                <van-icon name="replay" />
                <span>跟进</span>
              </div>
            </div>
          </div>
        </van-list>
      </template>
      <van-empty v-else description="暂无跟进" />

    </div>
  </div>
</template>
<script>
import navbar from "../../components/NavBar.vue";
import {escapeHtml } from "../../common/validate";

export default {
  name: "followUp",
  data() {
    return {
      itemList: [],
      loading: false,
      finished: false,
      pageNum: 0,
      pageSize: 10,
    };
  },
  components: {
    navbar,
  },
  mounted() {
    this.getFollowUp();
  },
  methods: {
    escapeHtml,
    onLoad() {
      this.loading = true;
      this.pageNum++;
      this.getFollowUp();
    },

    goDetail(id) {
      this.$router.push({
        path: "/my/followDetail",
        query: {
          id: id,
        },
      });
    },
    getFollowUp() {
      console.log("zzzzzzzzzzz");
      const data = {
        pageNum: this.pageNum,
        pageSize: this.pageSize,
      };
      this.$post("user/getFollowUp", data).then((res) => {
        if (res.status == 200) {
          this.finished = false;
          this.loading = false;
          console.log(res.data.list);
          this.itemList = this.itemList.concat(res.data.list);
          let flag = false;
          //防止出现每页楼盘长度等于查询的楼盘长度
          if (
            res.data.list.length == res.pageSize &&
            res.data.count % this.pageSize == this.pageNum &&
            res.data.count / this.pageSize == 0
          ) {
            flag = true;
          }
          if (
            this.itemList.length >= res.data.count ||
            res.data.list.length < this.pageSize ||
            flag
          ) {
            this.finished = true;
          }
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
.follow-up {
  .follow-box {
    .follow-item {
      background: #fff;
      margin-top: 8px;
      padding: 10px;
      font-size: 12px;
      color: #888;
      line-height: 1 !important;
      .follow-content {
        flex: 1;
        border-bottom: 1px solid #eee;
        display: flex;
        justify-content: space-between;
        padding-bottom: 10px;
        .follow-msg {
          display: flex;
          .follow-icon {
            width: 32px;
            height: 32px;
            border-radius: 50%;
            background: #5bb3f9;
            color: #fff;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 15px;
            margin-right: 5px;
          }
          .item-box {
            flex: 1;
            > div {
              padding: 5px 0;
            }
            .item-msg {
              display: flex;
              .item-name {
                color: #333;
                font-size: 14px;
                margin-right: 5px;
                padding-top: 0;
              }
              .item-phone {
                font-size: 13px;
                padding: 0;
              }
            }
            .item-intention {
              display: flex;
              p {
                flex-shrink: 0;
              }
              span {
                font-size: 13px;
                color: #00e;
              }
            }
          }
        }
        .follow-label {
          padding-top: 10px;
          flex-shrink: 0;
          color: #f00;
          &.active {
            color: #00d3c4;
          }
        }
      }
      .follow-bottom {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 10px;
        padding: 0 10px;
        .item-btn {
          span {
            margin-left: 5px;
          }
        }
      }
    }
  }
}
</style>